.properties__switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $sm $l;
  border-radius: $s;
  background-color: $success;
  width: 100%;
  height: 100%;
  min-height: 54px;
  max-height: 54px;
  cursor: pointer;

  & > span {
    color: $whiteLight;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }

  & > img {
    margin: 0 $s 0 0;
  }

  &.switched {
    background-color: $error;
  }
}

.property-edit-switcher {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 191px;
  margin: 0 0 $s 0;
  padding: $s 0;

  &:last-child {
    margin: 0;
  }

  & > img {
    margin: 0 $s 0 0;
  }

  & > span {
    margin: 0 $s 0 0;
    color: $brown;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }

  &__button {
    background-color: $error;
    padding: 2px;
    border-radius: 100px;
    width: 100%;
    max-width: 42px;
    height: 100%;
    max-height: 24px;
    display: flex;
    cursor: pointer;

    &.switched {
      background-color: $success;
      justify-content: flex-end;
    }
  }

  &__selector {
    background-color: $whiteLight;
    border-radius: 1000px;
    width: 100%;
    max-width: 20px;
    height: 100%;
    min-height: 20px;
  }
}

.data-not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &__imageBox {
    display: flex;
    justify-content: center;
  }

  &__title {
    @include applyFontSchema($displayLarge, $mulishFamily, $bold);
  }

  &__subTitle {
    @include applyFontSchema($headlineSmall, $mulishFamily, $regular);
  }
  @media (max-width: 700px) {
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
  }
}

.create-property-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  gap: 12px;
  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 24px;
  }
}

.default-outline-button {
  background-color: transparent;
  border-radius: $xxxc;
  padding: $sm;
  color: $brownLight;
  width: 100%;
  max-width: 250px;
  border: $one solid $brownLight;
  transition: all 0.5s ease-in-out;

  & > span {
    color: $brownLight;
  }

  &:hover {
    background-color: $brownLight;

    & > span {
      color: $whiteLight;
    }

    & > svg {
      & path {
        stroke: $whiteLight;
      }
    }
  }
}

.create-property-back {
  margin: 24px 0 0 24px;
}

.control-panel {
  width: 100%;
  height: 80px;
  padding: 0 $xl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $whiteLight;
  flex-direction: row;
  user-select: none;
  @media (max-width: 900px) {
    height: auto;
    padding: 20px $xl;
  }

  &__drop-down {
    width: 100%;
    position: relative;
    cursor: pointer;
    // max-width: 199px;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 $xl 0 0;
    @media (max-width: 900px) {
      margin: 0;
    }
  }

  &__drop-down-fg {
    display: flex;
    min-width: 200px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $spaceWhite;
    border-radius: $s;
    padding: $s $sm;
    position: relative;

    & > span {
      color: $brown;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }

    &.opened {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
  }

  &__drop-down-layout {
    display: none;

    &.opened {
      position: absolute;
      display: flex;
      top: 100%;
      flex-direction: column;
      // position: relative;
      background-color: $whiteLight;
      border-bottom-left-radius: $sm;
      border-bottom-right-radius: $sm;
      border: 1px solid $spaceWhite;
    }
  }

  &__drop-down-item {
    padding: $s $sm;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    & div > span {
      color: $brown;
      white-space: nowrap;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }

    & .checkbox {
      max-width: 18px;
      min-width: 18px;
      max-height: 18px;
      min-height: 18px;
    }
  }

  &__buttons {
    // width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 8px;
    }
    // height: 36px;

    & > div {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    & > div > img {
      cursor: pointer;
      // margin: 0 $l 0 0;
    }
  }

  & .property-filter {
    margin: 0 $xl 0 0;
    max-width: 224px;
    @media (max-width: 900px) {
      margin: 0;
    }
  }

  & .properties__switcher {
    // max-width: 224px;
    min-height: 36px;
    max-height: 36px;
    white-space: nowrap;
    margin: 0 $xl 0 0;
    @media (max-width: 900px) {
      margin: 0;
    }
  }
}

.add-property-filter {
  padding: $xl;

  &__input-img {
    & > img {
      width: 12px;
      height: 12px;
    }
  }

  &__photo-feed {
    display: flex;
    // align-items: center;
    gap: 8px;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  &__panel {
    background-color: $whiteLight;
    padding: $l $xl;
    border-top-left-radius: $m;
    border-top-right-radius: $m;
    border-bottom: 1px solid $spaceWhite;
  }

  &__upload {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    // justify-content: space-between;
    margin: 0 0 $l 0;
    @media (max-width: 700px) {
      margin: 0;
      grid-template-columns: 1fr;
      // flex-direction: column;
      gap: 8px;
      margin-bottom: $sm;
    }
  }

  &__img-box {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__upload-item {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $sm;
    width: 100%;
    max-width: 892px;
    height: 100%;
    min-height: 520px;
    position: relative;
    margin: 0 $l 0 0;

    @media (max-width: 1100px) {
      min-height: 300px;
    }

    @media (max-width: 700px) {
      min-height: 250px;
    }

    @media (max-width: 500px) {
      min-height: 150px;
    }

    &:nth-child(2) {
      flex-direction: column;
      margin: 0;
      cursor: pointer;

      & > img {
        margin: 0 0 $m 0;
      }

      & > span {
        text-transform: uppercase;
      }
    }
  }

  &__upload-box {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__main-photo-box {
    position: relative;
    max-height: 520px;
    & > span {
      position: absolute;
      top: 2%;
      right: 2%;
    }
    & > img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: fill;
    }
  }

  &__dnd-photos-box {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    @media (max-width: 750px) {
      justify-content: center;
    }
  }

  &__youtube-video {
    & > div {
      // width: 100% !important;
      height: 450px !important;
      @media (max-width: 1500px) {
        width: 550px !important;
        // height: 400px !important;
      }
      @media (max-width: 1300px) {
        width: 500px !important;
        // height: 350px !important;
      }
      @media (max-width: 1100px) {
        width: 450px !important;
        height: 280px !important;
      }
      @media (max-width: 900px) {
        width: 400px !important;
        height: 250px !important;
      }
      @media (max-width: 800px) {
        width: 350px !important;
        height: 200px !important;
      }
      @media (max-width: 700px) {
        width: 400px !important;
        height: 230px !important;
      }
      @media (max-width: 550px) {
        width: 350px !important;
        height: 200px !important;
      }
      @media (max-width: 500px) {
        width: 300px !important;
        height: 160px !important;
      }
      @media (max-width: 450px) {
        width: 250px !important;
        height: 140px !important;
      }
      @media (max-width: 400px) {
        width: 200px !important;
        height: 120px !important;
      }
    }
    & > div {
      border-radius: 12px;
      & > div {
        & > iframe {
          border-radius: 12px;
        }
      }
    }
  }

  &__upload-item-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: $whiteLight;
    // border-radius: $sm;
    padding: $sm;
    // border: 1px solid $spaceWhite;
    width: 100%;
    // max-width: 512px;

    &.haveLink {
      position: absolute;
      top: 5%;
    }

    & > img {
      margin: 0 $s 0 0;
    }

    & > span {
      margin: 0 $s 0 0;
    }

    & > div {
      // counter-reset: $brownLight;
      max-width: 512px;
      // background-color: $whiteLight;
    }
  }

  &__upload-item-favorite {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    padding: $sm;
    border-radius: $s;
  }

  &__upload-photo {
    display: flex;
    flex-direction: column;
  }

  &__upload-photo-amount {
    width: 100%;
    max-width: 195px;
    height: 100%;
    max-height: 48px;
    border: 1px solid $brownLight;
    padding: $sm;
    border-radius: $s;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 $l 0;

    & > img {
      margin: 0 $s 0 0;
    }

    & > span {
      margin: 0 $s 0 0;
      color: $brown;
      white-space: nowrap;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }

    & > input {
      max-width: 20px;
      background-color: $whiteLight;
    }
  }

  &__upload-title {
    margin: 0 $s 0 0;
    color: $brown;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }

  &__upload-photo-button {
    padding: $l;
    border-radius: $s;
    background-color: $success;
    height: 100%;
    max-height: 72px;
    width: 100%;
    max-width: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__description-box {
    background-color: $whiteLight;
    padding: $l;
    width: 100%;
    border-bottom: 1px solid $spaceWhite;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__description-data {
    margin: 0 $m 0 0;
    & > div {
      margin-bottom: 12px;
    }
  }

  &__data {
    display: flex;
    flex-direction: row;
    margin: 0 0 $m 0;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    // max-width: 891px;
    height: 48px;
  }

  & .active-users-drop-down {
    margin: 0 $sm 0 0;
    cursor: pointer;
  }

  & .active-users-icon {
    padding: $sm;
    border-radius: $s;
    width: 100%;
    min-width: 285px;
    max-height: 48px;
    border: 1px solid $spaceWhite;
  }

  & .active-users-icon__photo-branch {
    display: none;
  }

  &__agents {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-width: 325px;
    max-width: 325px;
    margin: 0 $m 0 0;
    position: relative;
  }

  &__agents-fg {
    border: 1px solid $spaceWhite;
    padding: $sm;
    border-radius: $s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-height: 48px;

    &.opened {
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
      border-bottom: unset;

      & > img {
        rotate: 180deg;
      }
    }
  }

  &__agents-layout {
    display: none;

    &.opened {
      background-color: $whiteLight;
      height: auto;
      border: 1px solid $spaceWhite;
      border-bottom-left-radius: $s;
      border-bottom-right-radius: $s;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 48px;
    }
  }

  &__agents-item {
    display: flex;
    flex-direction: column;
    padding: $m;
  }

  &__agent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .agency-item {
    padding: unset;
    margin: 0 0 $m 0;
  }

  & .checkbox {
    margin: 0 0 $m 0;
  }

  &__agents-ref {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $spaceWhite;
    padding: $sm;
    border-radius: $s;
    justify-content: space-between;
    width: 100%;
  }

  &__agents-ref-label {
    display: flex;
    justify-content: flex-start;

    & > span {
      margin: 0 $s 0 0;
    }

    & > input {
      background-color: $whiteLight;
      width: 15%;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    // min-width: 255px;
    // max-width: 255px;
  }

  &__price-fg {
    border: 1px solid $spaceWhite;
    padding: $sm;
    border-radius: $s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-height: 48px;

    &.opened {
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
      border-bottom: unset;

      & > img {
        &:last-child {
          rotate: 180deg;
        }
      }
    }
  }

  &__price-label {
    & > label {
      width: 100%;

      & > input {
        width: 70%;
        background: $whiteLight;
      }
    }
  }

  &__price-layout {
    display: none;

    &.opened {
      background-color: $whiteLight;
      height: auto;
      padding: $sm;
      width: 100%;
      border: 1px solid $spaceWhite;
      border-bottom-left-radius: $s;
      border-bottom-right-radius: $s;
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }

  &__sub-title-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    @media (max-width: 700px) {
      grid-template-columns: 1fr;
    }
    & > div {
      border: 1px solid #c6c6c6;
      margin: 0px;
      & > div:first-child {
        padding: 12px 16px;
      }
    }
  }

  &__data-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    // max-width: 891px;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 $m 0;
    gap: 12px;
    @media (max-width: 700px) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__data-input-item {
    border: 1px solid $spaceWhite;
    padding: $sm;
    white-space: nowrap;
    border-radius: $s;
    width: 100%;
    height: 100%;
    max-height: 48px;
    overflow: hidden;
    // max-width: 441px;
    margin: 0 $m 0 0;

    @media (max-width: 700px) {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }

    & > label {
      width: 100%;

      & > span {
        margin: 0 $s 0 0;
      }

      & > input {
        background-color: $whiteLight;
        width: 80%;
      }
    }
  }

  &__presentation {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    & > span {
      color: $brown;
      margin: 0 $sm 0 0;
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);
    }
  }

  &__presentation-icon {
    display: flex;
    align-items: center;
    gap: 8px;

    & > img {
      cursor: pointer;
      width: 18px;
      height: 18px;
      // margin: 0 $s 0 0;
      display: block;
      &:last-child {
        margin: 0;
      }
    }
    & > div {
      cursor: pointer;
      & > div {
        & > img {
          display: block;
        }
      }
      & > img {
        display: block;
        cursor: pointer;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  &__description-ext {
    padding: $xxxl;
    border-radius: $s;
    background-color: $yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 152px;
    width: 100%;
    max-width: 112px;
    margin: 0 $sm 0 0;
    @media (max-width: 600px) {
      padding: 24px;
      max-width: 78px;
    }

    & > span {
      text-transform: uppercase;
      color: $whiteLight;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }
  }

  &__description-property {
    width: 100%;
    height: 100%;
    max-height: 150px;
    border: 1px solid $spaceWhite;
    border-radius: $s;
    padding: $s $xl $s $sm;

    & > textarea {
      width: 100%;
      height: 100px;
      background: $whiteLight;
      @media (max-width: 900px) {
        height: 50px;
      }
    }
  }

  &__edit-box {
    background-color: $whiteLight;
    padding: $l;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $spaceWhite;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &__edit-panel {
    margin: 0 $sm 0 0;
  }

  &__edit-place {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 10px;
    & > div {
      border: 1px solid #c6c6c6;
      margin: 0px;
      & > div:first-child {
        padding: 12px 16px;
      }
    }
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    & .drop-down {
      margin: 0 $m $m 0;

      &:last-child {
        margin: 0 0 $m 0;
      }
    }
  }

  &__edit-area {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 8px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    & .drop-down-with-icon {
      margin: 0 $m $m 0;

      &:last-child {
        margin: 0 0 $m 0;
      }
    }
  }

  &__edit-area-select {
    width: 100%;
    & > div {
      border: 1px solid #c6c6c6;
      margin: 0px;
      & > div:first-child {
        padding: 11px 16px;
      }
    }
  }

  &__edit-room {
    display: flex;
    flex-direction: row;
    gap: 12px;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__edit-room-item {
    padding: $sm;
    border-radius: $s;
    border: 1px solid $spaceWhite;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-height: 48px;
    width: 100%;
    // max-width: 168px;
    margin: 0 $m 0 0;

    &:last-child {
      margin: 0;
    }

    & > input {
      width: 15%;
      background-color: $whiteLight;
    }
  }

  &__edit-room-item-label {
    display: flex;
    align-items: center;

    & > img {
      margin: 0 $s 0 0;
    }
  }

  &__certificate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $sm 0;
    margin: 0 $m 0 0;
    height: 100%;
    // max-height: 112px;
  }

  &__certificate-range {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__switcher {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 112px;
  }
}

.property-features {
  background-color: $whiteLight;

  &__label {
    cursor: pointer;
    padding: $l;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > img {
      margin: 0 $sm 0 0;

      &.opened {
        rotate: 180deg;
      }
    }
  }

  &__layout {
    padding: 0 $xl;
    display: none;

    &.opened {
      display: flex;
      flex-direction: column;
      gap: 32px;
      @media (max-width: 900px) {
        gap: 0;
      }
    }

    & .checkbox {
      min-width: 18px;
      max-width: 18px;
      min-height: 18px;
      max-height: 18px;
      margin: 0 $sm 0 0;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    @media (max-width: 1600px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

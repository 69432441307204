.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 $xl 0;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    @media (max-width: 900px) {
      justify-content: space-around;
      width: 100%;
      margin: 0 0 $sm 0;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      text-align: center;
      width: 100%;
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $whiteLight;
    border-radius: $s;
    border: 1px solid $spaceWhite;
    padding: $sm;

    @media (max-width: 900px) {
      width: 100%;
      max-width: 40%;
      justify-content: center;
    }

    @media (max-width: 600px) {
      max-width: unset;
    }

    & .checkbox {
      margin: 0 $s 0 0;
    }

    & > span {
      color: $brown;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }
  }
}

.item-data-controller {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $spaceWhite;
  border-radius: $s;
  margin: 0 $s 0 0;
  width: 100%;
  max-width: 301px;
  height: 100%;
  max-height: 48px;

  &.opened {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  &__drop-down {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: $sm;

    & > span {
      color: $brownLight;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }

    & > img {
      &.opened {
        rotate: 180deg;
      }
    }
  }

  &__drop-down-layout {
    display: none;
    width: 100%;

    &.opened {
      display: flex;
      width: 100%;
      min-width: 301px;
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: $whiteLight;
      border-bottom-left-radius: $s;
      border-bottom-right-radius: $s;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: $sm;

    & .checkbox {
      margin: 0;
    }
  }
}

.property-filter {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &__item {
    border-radius: $s;
    padding: $s $sm;
    background-color: $maline;
    display: flex;
    gap: 8px;
    min-width: 220px;
    flex-direction: row;
    justify-content: space-between;

    &.long-term {
      background-color: $info;
    }

    &.secret {
      background-color: $edit;
    }

    &.archive {
      background-color: $brownLight;
    }

    &.on-the-market {
      background-color: $success;
    }

    &.private {
      background-color: $orange;
    }

    &.only-for-customers {
      background-color: $lawanda;
    }

    &.only-for-agents {
      background-color: $aqua;
    }

    &.sold {
      background-color: $maline;
    }

    & > img {
      &.opened {
        rotate: 180deg;
      }
    }

    & > span {
      text-transform: uppercase;
      white-space: nowrap;
      color: $whiteLight;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }
  }

  &__layout {
    display: none;

    &.opened {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $whiteLight;
      padding: $sm;
      border-radius: $sm;
      box-shadow: 0 0 20px #403b372e;
      top: 40px;
      z-index: 2;
    }

    & > span {
      width: 100%;
      color: $whiteLight;
      text-transform: uppercase;
      padding: $s $sm;
      border-radius: $s;
      background-color: $edit;
      white-space: nowrap;
      margin: 0 0 $sm 0;
      text-align: center;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);

      &:last-child {
        margin: 0;
      }

      &.long-term {
        background-color: $info;
      }

      &.secret {
        background-color: $edit;
      }

      &.archive {
        background-color: $brownLight;
      }

      &.on-the-market {
        background-color: $success;
      }

      &.private {
        background-color: $orange;
      }

      &.only-for-customers {
        background-color: $lawanda;
      }

      &.only-for-agents {
        background-color: $aqua;
      }

      &.sold {
        background-color: $maline;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }
}

.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 $m;

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $whiteLight;
    padding: $m $c;
    color: $brown;
    border-radius: $xxxl;
    cursor: pointer;
    user-select: none;

    & > img {
      @media screen and (max-width: 440px) {
        width: $l;
        height: $l;
      }
    }

    @media screen and (max-width: 440px) {
      padding: $s $xl;
    }

    & > span {
      margin: 0 0 0 $m;
      @include applyFontSchema($headlineSmall, $mulishFamily, $regular);

      @media screen and (max-width: 440px) {
        margin: 0 0 0 $s;
        @include applyFontSchema($titleSmall, $mulishFamily, $regular);
      }
    }
  }
}

.drop-down-with-icon {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-width: 276px;
  position: relative;

  &:nth-child(2) {
    // min-width: 288px;
  }

  &:last-child {
    // min-width: 302px;
  }

  &__fg {
    padding: $sm;
    border-radius: $s;
    border: 1px solid $spaceWhite;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-height: 48px;
  }

  &__fg-icon {
    display: flex;
    align-items: center;

    & > img {
      margin: 0 $s 0 0;
    }
  }

  &__fg-selector {
    display: flex;
    align-items: center;

    & > span {
      margin: 0 $s 0 0;
    }
  }

  &__layout {
    display: none;

    &.opened {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 100%;
      top: 50px;
    }
  }
}

.active-users-icon {
  max-height: 36px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $brownLight;
  border-radius: $xxxl;
  padding: $s;
  height: 100%;
  max-height: 36px;

  & > span {
    margin: 0 $xxs 0 0;
    color: $brown;
    @include applyFontSchema($titleMedium, $mulishFamily, $bold);

    @media (max-width: 1150px) {
      margin: 0 $s 0 0;
    }

    @media (max-width: 500px) {
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }
  }

  &__photo-branch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 25%;
    margin: 0 $s 0 0;

    @media (max-width: 1450px) {
      display: none;
    }

    & > img {
      width: 27px;
      height: 27px;

      &:nth-child(1) {
        position: relative;
        z-index: 10;
      }

      &:nth-child(2) {
        position: relative;
        right: 10px;
        z-index: 1;
      }

      &:nth-child(3) {
        position: relative;
        right: 20px;
        z-index: 0;
      }
    }
  }

  &__amount {
    height: 22px;
    width: 27px;
    background-color: $success;
    padding: $s;
    border-radius: $xl;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $whiteLight;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }
}

.agents-form-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  padding: 18px 24px;
  & > div {
    & > div {
      width: 100%;
      & > div {
        & > img {
          top: 18%;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__select {
    & > div {
      width: 100%;
      margin: 0;
      border: 1px solid #c6c6c6;
      white-space: nowrap;
      border-radius: 8px;
      height: auto;
      max-height: none;
      & > div {
        &:first-child {
          padding: 12px 16px;
        }
      }
    }
  }
}

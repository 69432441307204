.agents {
  &__drop-down {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $whiteLight;
    border-radius: $s;
    // margin: 0 $l 0 0;
    position: relative;
    // max-width: 180px;
    align-items: center;
    cursor: pointer;
    height: 100%;
    max-height: 68px;

    @media (max-width: 1650px) {
      // margin: 0 0 16px 0;
      max-width: unset;
    }
  }

  &__drop-down-fg {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 22px $m;

    & > img {
      &.opened {
        rotate: 180deg;
      }
    }
  }

  &__drop-down-icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > img {
      margin: 0 $sm 0 0;
      width: 25px;
      height: 25px;
    }
  }

  &__drop-down-layout {
    display: none;

    &.opened {
      width: 100%;
      position: relative;
      top: 2px;
      display: flex;
      flex-direction: column;
      background-color: $whiteLight;
      // border-radius: $s;
      padding: $s $one;
      z-index: 2;

      @media (max-width: 1650px) {
        margin: 0 auto;
      }
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $s $m $s $xxxl;
      cursor: pointer;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  border: 0;
  border-radius: 0;

  @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
}

html,
body,
#app,
.rive-fullscreen {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: $brownLight;
}

::-webkit-scrollbar-thumb {
  background-color: $brownDark;
}

*:disabled {
  opacity: 0.3;
  user-select: none;
  pointer-events: none;
}

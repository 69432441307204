.agent-docs {
  // width: 300px;
  margin-top: 14px;
  border: 1px solid #a09b97;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  box-sizing: content-box;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  div {
    width: 64px;
    // height: 64px;
    // border: 1px dashed #a09b97;
    border-radius: 8px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    // width: 100px;
    cursor: pointer;

    @media (max-width: 700px) {
      padding: 8px;
      border-bottom: 1px solid #a09b97;
    }

    @media (min-width: 700px) {
      &::after {
        content: '';
        width: 1px;
        height: 80px;
        background-color: #a09b97;
      }
    }
  }

  .download-button {
    width: 64px;
    height: 64px;
    border: 1px dashed #a09b97;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & > img {
      width: 20px;
      height: 20px;
    }
  }
}

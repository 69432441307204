.property-features-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 $sm 0;
  white-space: nowrap;

  & > img {
    margin: 0 $s 0 0;
  }
}

.dragable-photo-container {
  position: relative;
  width: 150px;
  height: 140px;
  margin-bottom: 8px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  @media (max-width: 1050px) {
    width: 130px;
    height: 120px;
  }

  @media (max-width: 750px) {
    width: 115px;
    height: 115px;
  }

  &:hover {
    opacity: 0.8;
  }

  .index-badge {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 2px 8px;
    z-index: 1;
  }

  .remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 2px 8px;
    z-index: 1;

    img {
      cursor: pointer;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    z-index: 0;
  }

  &.isDragging {
    opacity: 0.5;
  }
}

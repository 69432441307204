.drop-down {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-width: 276px;
  // max-width: 276px;
  position: relative;

  &:nth-child(2) {
    // min-width: 288px;
  }

  &:last-child {
    // min-width: 302px;
  }

  &__fg {
    padding: $sm;
    border-radius: $s;
    border: 1px solid $spaceWhite;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.opened {
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
    }
  }

  &__layout {
    display: none;

    &.opened {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $whiteLight;
      border-radius: $s;
      border: 1px solid $spaceWhite;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      position: absolute;
      width: 100%;
      top: 50px;
    }
  }

  &__layout-button {
    background-color: $success;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 48px;
    max-height: 48px;

    & > span {
      color: $whiteLight;
    }

    & > img {
      margin: 0 $s 0 0;
    }
  }

  &__layout-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $sm;
  }

  &__layout-item-icon {
    display: flex;
    flex-direction: row;

    & > img {
      width: 24px;
      height: 24px;

      &:first-child {
        margin: 0 $s 0 0;
      }
    }
  }
}

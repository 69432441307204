.agency-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: $sm;

  & > img {
    width: 22px;
    height: 22px;
    margin: 0 $s 0 0;
  }

  & > span {
    margin: 0 $sm 0 0;
    color: $brown;
    @include applyFontSchema($titleMedium, $mulishFamily, $medium);
  }

  &.request {
    border: 1px solid $yellow;
    border-radius: $xxxl;
    padding: $s;
    height: 100%;
    max-height: 36px;
    width: auto;

    & > span {
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &.properties {
    padding: $sm 0;
    height: 100%;
    max-height: 36px;
    width: auto;
  }
}

.requests-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $whiteLight;
  border-radius: $m;
  height: 100%;
  min-height: 203px;
  margin: 0 0 $l 0;

  &__fg {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;

    @media (max-width: 950px) {
      flex-direction: column;
    }
  }

  &__mark {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: $m;
    border-bottom-left-radius: $m;
    width: 100%;
    max-width: 203px;
    padding: 87.5px 19px;
    user-select: none;
    background-color: $lawanda;
    height: 100%;
    text-align: center;

    @media (max-width: 1900px) {
      padding: 150px 19px;
    }

    @media (max-width: 950px) {
      padding: 0;
      max-width: unset;
      border-top-left-radius: $m;
      border-top-right-radius: $m;
      border-bottom-left-radius: unset;
      padding: $sm;
    }

    & > span {
      color: $whiteLight;
      @include applyFontSchema($titleLarge, $mulishFamily, $bold);
    }

    &.opened {
      border-bottom-left-radius: unset;
    }

    &.services {
      background-color: $lawanda;
    }

    &.request {
      background-color: $info;
    }

    &.sell {
      background-color: $edit;
    }

    &.valuation {
      background-color: $success;
    }

    &.ask-for-viewing {
      background-color: $orange;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    padding: $l $xl;
    border-right: 1px solid $spaceWhite;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 0 $xl 0 0;

    @media (max-width: 1250px) {
      padding: $s $m;
    }

    @media (max-width: 950px) {
      padding: $l $xl;
      border-right: unset;
      margin: 0;
      border-bottom: 1px solid $spaceWhite;
    }

    @media (max-width: 360px) {
      padding: $sm;
      text-align: left;
    }

    & > h1 {
      color: $brown;
      margin: 0 0 $sm 0;
      @include applyFontSchema($titleLarge, $mulishFamily, $bold);
    }

    & > span {
      color: $brown;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

      @media (max-width: 1060px) {
        @include applyFontSchema($bodyMedium, $mulishFamily, $regular);
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $xl $xl $xl 0;
    align-items: center;
    justify-content: center;

    @media (max-width: 360px) {
      padding: $sm;
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $s 0;

    & > span {
      color: $error;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }

    & .checkbox {
      border-color: $error;
      margin: 0 $s 0 0;
    }
  }
}

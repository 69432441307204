.active-users-icon {
  max-height: 36px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $brownLight;
  border-radius: $xxxl;
  padding: $s;
  height: 100%;
  max-height: 36px;

  &__photo-branch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 25%;
    margin: 0 $s 0 0;

    c & > img {
      width: 27px;
      height: 27px;

      &:nth-child(1) {
        position: relative;
        z-index: 10;
      }

      &:nth-child(2) {
        position: relative;
        right: 10px;
        z-index: 1;
      }

      &:nth-child(3) {
        position: relative;
        right: 20px;
        z-index: 0;
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    & > span {
      margin: 0 $s 0 0;
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);

      @media (max-width: 1150px) {
        margin: 0 $s 0 0;
      }

      @media (max-width: 500px) {
        @include applyFontSchema($titleSmall, $mulishFamily, $bold);
      }
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    &.opened {
      rotate: 180deg;
    }
  }

  &__amount {
    height: 22px;
    width: 27px;
    background-color: $success;
    padding: $s;
    border-radius: $xl;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $whiteLight;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }
}

.active-users-drop-down {
  &__layout {
    display: none;

    &.opened {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 315px;
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: $whiteLight;
      padding: 4px;
      border-radius: $sm;
      box-shadow: 0 0 20px #403b372e;
      position: absolute;
    }
  }

  &__selector {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 $m 0 0;

    & > img {
      width: 22px;
      height: 22px;
      margin: 0 $s 0 0;
    }

    & > span {
      margin: 0 $sm 0 0;
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &__selected {
    padding: $sm 0 0 0;
    border-bottom: 1px solid $brownLight;

    &:nth-child(3) {
      border: unset;
    }

    & > span {
      padding: $sm;
    }
  }

  &__selected-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $sm;

    & .checkbox {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
    }
  }

  &__selected-user {
    display: flex;
    align-items: center;

    & > img {
      width: 22px;
      height: 22px;
      margin: 0 $s 0 0;
    }

    & > span {
      margin: 0 $sm 0 0;
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);
    }
  }
}

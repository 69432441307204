.search {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: $sm;
  border-bottom: 1px solid $spaceWhite;
  cursor: pointer;

  & > input {
    width: 50%;
    background-color: $whiteLight;
  }

  & > img {
    margin: 0 $s 0 0;
  }
}

.search-2 {
  position: relative;
  width: 100%;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }

  & > input {
    width: 100%;
    padding: 24px 0 24px 52px;
    background-color: #f3f3f3;
    border-radius: 8px;
  }
}

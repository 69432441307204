.filter-item {
  border-radius: $s;
  background-color: $whiteLight;
  padding: $sm $l;
  margin: 0 $m 0 0;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (max-width: 1070px) {
    padding: $s;
  }

  @media (max-width: 900px) {
    padding: $sm $l;
  }

  @media (max-width: 650px) {
    margin: 0 0 $sm 0;
  }

  &.active {
    &.reset {
      fill: none;
    }

    &::before {
      content: '';
      background-color: $yellow;
    }
  }

  &:last-child {
    margin: 0;
  }

  & > span {
    color: $brown;
    @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

    // @media (max-width: 650px) {
    //   @include applyFontSchema($bodySmall, $mulishFamily, $regular);
    // }
  }
}

.docs-drop-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 $one 0;

  &__layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: $whiteLight;
    padding: $sm;
    cursor: pointer;

    & > img {
      margin: 0 $l 0 0;
      &.opened {
        rotate: 180deg;
      }
    }

    & > span {
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &__item-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $whiteLight;
    padding: $sm;
    border-radius: $s;

    &.closed {
      display: none;
    }

    &.opened {
      border-radius: 0;
      justify-content: flex-start;
      align-items: flex-start;

      @media (max-width: 1060px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__item-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 222px;
    height: 100%;
    min-height: 80px;
    padding: $s $m;
    border: 1px solid $brownLight;
    border-radius: $s;

    & > img {
      padding: 22px;
      border: 1px solid $brownLight;
      border-style: dashed;
      border-radius: $s;
      display: block;
      cursor: pointer;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-right: 1px solid $brownLight;
    margin: 0 $sm 0 0;

    & > img {
      margin: 0 4px 0 0;
    }

    & > span {
      color: $brown;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }
}

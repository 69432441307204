.person-data {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 $s 0;

  @media (max-width: 900px) {
    justify-content: space-evenly;
  }

  @media (max-width: 900px) {
    margin: 0 0 $sm 0;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    margin: 0;
  }

  &__photo {
    background-color: $white;
    border-radius: 50%;
    padding: $m;
    margin: 0 $m 0 0;
    width: 100%;
    height: 100%;
    max-width: 80px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 50%;
      object-fit: cover;
      @media (max-width: 700px) {
        width: 60px;
        height: 60px;
        min-width: 60px;
      }
    }

    @media (max-width: 700px) {
      max-width: 60px;
      max-height: 60px;
      margin: 0;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0 $l 0 0;

    & .name {
      margin: 0 0 $xs 0;
      color: $brown;
      @include applyFontSchema($titleLarge, $mulishFamily, $bold);
    }

    & .birthday {
      color: $edit;
      @include applyFontSchema($labelLarge, $mulishFamily, $medium);
    }

    @media (max-width: 460px) {
      margin: 0;
      align-items: center;
    }
  }

  &__audocat {
    height: 100%;
    max-height: $xxxl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid $spaceWhite;
    padding: $sm;
    border-radius: $xxl;

    & span {
      white-space: nowrap;
    }

    @media (max-width: 460px) {
      margin: 0 0 $s 0;
    }
  }
}

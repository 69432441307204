.agents-preferences-container {
  margin-top: 15px;
  display: grid;
  grid-template-columns: auto auto 290px auto;
  gap: 12px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 6px;
  }

  .price {
    display: flex;
    gap: 12px;
    align-items: center;
    @media (max-width: 1200px) {
      gap: 4px;
    }
  }

  & > div {
    & > div {
      width: 100%;
      & > div {
        & > img {
          top: 18%;
        }
      }
    }
  }
  &__select {
    & > div {
      width: 100%;
      margin: 0;
      border: 1px solid #c6c6c6;
      white-space: nowrap;
      border-radius: 8px;
      height: auto;
      max-height: none;
      & > div {
        &:first-child {
          padding: 12px 16px;
        }
      }
    }
  }
}
.preferences-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  @media (max-width: 1200px) {
    justify-content: end;
    // grid-template-columns: 1fr auto;
    gap: 4px;
  }
}

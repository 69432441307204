.customers-card__layout {
  background: #f5f5f5;
  border-radius: 8px;
  margin: 32px;

  .main-section {
    &.open {
      .data-content {
        max-height: 3000px; // Подгоните под высоту контента
        transition: max-height 0.5s ease-in;
      }
    }
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 24px;
      border-bottom: 1px solid #e0dbd5;
      cursor: pointer;
      @media (max-width: 800px) {
        flex-direction: column-reverse;
        padding: 12px 4px;
        .data-item__person-social-data {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .head-user {
      display: flex;
      align-items: center;
      gap: 24px;
      @media (max-width: 800px) {
        flex-direction: column-reverse;
        gap: 8px;
      }
    }
  }

  .arrow-icon {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.3s ease;

    &.opened {
      transform: rotate(180deg);
    }
  }

  .data-content {
    max-height: 0;
    overflow: visible;
    transition: max-height 0.3s ease-out;
  }

  .section {
    border-top: 1px solid #e0dbd5;

    &.open .section-content {
      max-height: 1000px; // Подгоните под высоту контента
      transition: max-height 0.5s ease-in;
      padding: 0px 24px 16px;
      overflow: visible;
    }

    &.disabled {
      .section-header {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .section-content {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .section-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 5px;
      padding: 12px 24px;

      &:hover {
        background: #e0e0e0;
      }

      .section-title {
        font-weight: bold;
      }

      .add-button {
        cursor: pointer;
        padding: 6px 64px;
        background-color: #55a066;
        border-radius: 8px;
        color: #fff;
        display: flex;
        font-size: 12px;
        align-items: center;
        gap: 4px;
        margin-left: 8px;
        transition: transform 0.2s ease;

        @media (max-width: 700px) {
          padding: 6px 28px;
        }

        &:hover {
          transform: scale(1.01);
        }

        & > img {
          width: 18px;
          height: 18px;
        }
      }

      .arrow-icon {
        transform: rotate(0deg);
        transition: transform 0.3s ease;

        &.opened {
          transform: rotate(180deg);
        }
      }
    }

    .section-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
    }

    .disabled-content {
      color: #ccc;
    }
  }
}

.analytics-button {
  padding: $m;
  background-color: $whiteLight;
  border-radius: $m;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 181px;
  min-height: 141px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #efefef;
  }

  & > img {
    margin: 0 0 $s 0;
  }

  & > span {
    width: 100%;
    max-width: 50%;
  }
}

.add-panel {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: auto;
    gap: 16px;
  }

  @media (max-width: 787px) {
    grid-template-columns: auto;
    gap: 16px;
  }

  form {
    padding: 10px;
    border-radius: 8px;
    background-color: #f3f3f3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    align-items: center;
    gap: 12px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr 2fr auto;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// .agents-panel {
//   display: grid;
//   grid-template-columns: 2fr 1fr;
//   gap: 24px;
//   margin-top: 32px;

//   padding: 0 32px;
// }

// .agents-panel-filters {
//   display: flex;
//   align-items: center;
//   gap: 24px;
//   &__input {
//     width: 100%;
//     & > div {
//       padding: 24px 16px;
//       max-height: 100%;
//       height: 100%;
//     }
//   }
//   &__select {
//     width: 50%;
//     & > div {
//       max-height: 100%;
//       & > div {
//         &:first-child {
//           padding: 25px 16px;
//         }
//       }
//     }
//   }
// }

// .agents-form {
//   display: flex;
//   align-items: center;
//   gap: 12px;
//   padding: 10px;
//   background-color: #f3f3f3;
//   border-radius: 8px;
//   & > div {
//     margin: 0;
//     border: 1px solid #c6c6c6;
//     white-space: nowrap;
//     // max-height: 32px;
//     border-radius: 8px;
//     height: auto;
//     max-height: none;
//     & > div {
//       &:first-child {
//         padding: 12px 16px;
//       }
//     }
//   }
// }

.preferences {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: $whiteLight;
    padding: $sm;
    border-radius: 0 0 $s $s;
    cursor: pointer;

    &.open {
      border-radius: 0;
    }

    & > img {
      margin: 0 $l 0 0;
      &.opened {
        rotate: 180deg;
      }
    }

    & > span {
      margin: 0 $sm 0 0;
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &__item-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $whiteLight;
    padding: $sm;
    border-radius: $s;

    &.closed {
      display: none;
    }

    &.opened {
      border-radius: 0 0 $s $s;
      justify-content: flex-start;
      align-items: flex-start;

      @media (max-width: 1060px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__item-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }

    & .item-data-controller {
      @media (max-width: 900px) {
        max-width: unset;
        width: 100%;
        margin: 0 0 $s 0;
      }
    }

    & .item-data-controller__drop-down-layout.opened {
      @media (max-width: 900px) {
        width: 100%;
        min-width: unset;
      }
    }
  }

  &__set-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 133px;

    @media (max-width: 1100px) {
      max-width: unset;
    }

    @media (max-width: 550px) {
      flex-direction: column;
    }

    & > input {
      width: 100%;
      min-width: 133px;
      padding: $sm $xxxl $sm $sm;
      border-radius: $s;
      border: 1px solid $spaceWhite;
      background-color: $whiteLight;
      margin: 0 6px 0 0;
      cursor: pointer;
      height: 100%;
      max-height: 48px;

      @media (max-width: 550px) {
        margin: 0 0 $s 0;
      }

      &:nth-child(3) {
        margin: 0 16px 0 0;

        @media (max-width: 550px) {
          margin: 0 0 $s 0;
        }
      }

      &::placeholder {
        color: $brownLight;
        @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
      }
    }

    & > img {
      margin: 0 6px 0 0;

      @media (max-width: 550px) {
        margin: 0 0 $s 0;
      }
    }

    & .checkbox {
      margin: 0 $s 0 0;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    border-radius: $s;
    padding: $s $xxl;
    width: 100%;
    max-width: 179px;
    height: 100%;
    max-height: 36px;
    background-color: $success;

    @media (max-width: 450px) {
      max-width: 30%;
    }

    & > img {
      max-width: 18px;
      max-height: 18px;
      margin: 0 4px 0 0;
    }

    & > span {
      color: $whiteLight;
      @include applyFontSchema($labelMedium, $mulishFamily, $bold);
    }
  }
}

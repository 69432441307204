.navbar {
  width: 100%;
  height: 80px;
  padding: 0 $xl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $whiteLight;
  flex-direction: row;
  user-select: none;

  @media screen and (max-width: 880px) {
    padding: 0;
  }

  &-top {
    display: flex;
    width: 50%;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media screen and (max-width: 1400px) {
      width: 70%;
    }

    @media screen and (max-width: 1100px) {
      width: 100%;
    }

    & > img {
      display: block;
      width: 80px;
      height: 80px;
      user-select: none;
    }

    & > svg {
      margin: 26px 0 42px 0;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 0 $s 0 0;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 880px) {
      display: none;
    }

    & > span {
      color: $brown;
      text-transform: uppercase;
      @include applyFontSchema($labelSmall, $pfdFamily, $regular);
    }

    & > img {
      margin: 0 0 $xs 0;
    }

    &.active {
      & > img {
        &.reset {
          fill: none;
        }
      }

      &::before {
        content: '';
        width: 80px;
        height: 2px;
        background-color: $yellow;
        position: absolute;
        top: 78px;

        @media screen and (max-width: 1060px) {
        }
      }
    }
  }

  &-mobile {
    display: none;
    width: 100%;
    padding: $xl;
    height: calc(100vh - 80px);
    position: fixed;
    z-index: 10;
    top: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $whiteLight;

    & > a {
      color: $black;
      margin: 0 0 $xxl 0;
      text-align: center;
      @include applyFontSchema($headlineSmall, $pfdFamily, $bold);
    }

    &.opened {
      display: flex;
    }
  }

  &-burger {
    display: none;
    width: 80px;
    height: 80px;

    @media screen and (max-width: 880px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

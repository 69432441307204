.switcher-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background-color: $maline;
  border-radius: $s;
  height: 100%;
  min-height: 36px;

  & > span {
    padding: $s $sm;
    color: $whiteLight;
    @include applyFontSchema($bodySmall, $mulishFamily, $bold);
  }

  &.request {
    min-height: 18px;
    border-radius: $sm;

    & > span {
      padding: $one $s;
      color: $whiteLight;
    }
  }
}

.agency-item-drop-down {
  width: auto;
  height: 100%;
  margin: 0 $sm 0 0;

  &__fg {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $sm;
    border: 1px solid $yellow;
    border-radius: $xxxl;
    padding: $s;
    height: 100%;
    max-height: 36px;

    & > img {
      &.opened {
        rotate: 180deg;
      }
    }
  }

  &__layout {
    display: none;

    &.opened {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 315px;
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: $whiteLight;
      padding: 4px;
      border-radius: $sm;
      box-shadow: 0 0 20px #403b372e;
      position: absolute;
    }
  }

  &__selector {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 $m 0 0;

    & > img {
      width: 22px;
      height: 22px;
      margin: 0 $s 0 0;
    }

    & > span {
      margin: 0 $sm 0 0;
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &__selected {
    padding: $sm 0 0 0;
    border-bottom: 1px solid $brownLight;

    &:nth-child(3) {
      border: unset;
    }

    & > span {
      padding: $sm;
    }
  }

  &__selected-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $sm;

    & .checkbox {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
    }
  }
}

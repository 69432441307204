.analytics {
  padding: $xl;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 930px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 450px) {
    padding: $m;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 930px) {
      flex-direction: row;
      max-width: 370px;
      margin: 0 0 $l 0;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      min-width: 100%;
    }

    & .analytics-button {
      margin: 0 0 $m 0;

      @media screen and (max-width: 930px) {
        margin: 0 $l 0 0;
      }

      @media screen and (max-width: 450px) {
        margin: 0 0 $s 0;
      }

      &:last-child {
        margin: 0;
      }

      & > span {
        width: 100%;
        max-width: 100px;
        color: $brown;
        @include applyFontSchema($titleSmall, $mulishFamily, $medium);
      }
    }
  }
}

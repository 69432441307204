.customers-panel {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin-top: 32px;

  padding: 0 32px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.customers-panel-filters {
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  &__input {
    width: 100%;
    & > div {
      padding: 24px 16px;
      max-height: 100%;
      height: 100%;
    }
  }
  &__select {
    width: 50%;
    @media (max-width: 1200px) {
      width: 100%;
    }
    & > div {
      max-height: 100%;
      & > div {
        &:first-child {
          padding: 25px 16px;
        }
      }
    }
  }
}

.customers-form {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr auto;
  align-items: center;
  gap: 12px;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 8px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  // & > div {
  //   margin: 0;
  //   border: 1px solid #c6c6c6;
  //   white-space: nowrap;
  //   // max-height: 32px;
  //   border-radius: 8px;
  //   height: auto;
  //   max-height: none;
  //   & > div {
  //     &:first-child {
  //       padding: 12px 16px;
  //     }
  //   }
  // }
}

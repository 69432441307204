.button-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > span {
    text-transform: uppercase;
    margin: 0 $s 0 0;
    white-space: nowrap;
    @include applyFontSchema($labelMedium, $mulishFamily, $medium);
  }

  & > img {
    width: $m;
    height: $m;
  }
}

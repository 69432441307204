.data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 $l 0;

  &__layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $whiteLight;
    padding: $sm $l;
    border-radius: $s;

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 460px) {
      flex-direction: column-reverse;
    }

    &.open {
      border-radius: $s $s 0 0;
    }

    & > img {
      margin: 0 $l 0 0;
      cursor: pointer;

      @media (max-width: 460px) {
        margin: 0;
      }

      &.opened {
        rotate: 180deg;
      }
    }
  }

  &__person-social-data {
    display: flex;
    flex-direction: row;
    gap: 16px;

    & a > img {
      // margin: 0 $xl 0 0;
      width: 32px;
      height: 32px;

      &:last-child {
        margin: 0;
      }

      @media (max-width: 460px) {
        margin: 0 $m 0 0;
      }
    }

    @media (max-width: 460px) {
      margin: 0 0 $sm 0;
      justify-content: space-around;
      width: 100%;
    }
  }

  &__item-data {
    display: flex;
    flex-direction: column;
    margin: 0 $s 0 0;

    @media (max-width: 1520px) {
      width: 100%;
      align-items: center;
      margin: 0 0 $s 0;
    }

    & .file-uploader {
      cursor: pointer;
      width: 100%;
      margin-bottom: 8px;
    }

    & .react-datepicker-wrapper {
      width: 100%;
      margin-bottom: 8px;

      & div {
        & img {
          top: 18%;
        }
        & input {
          padding: 6px 10px 5px 30px;
        }
      }
    }

    &:last-child {
      margin: 0;
    }

    &:first-child {
      margin: 0 $l 0 0;
    }

    & .form-input {
      width: 100%;
      height: 100%;
      max-height: 48px;
      min-height: 48px;
      max-width: 301px;
      padding: $sm;
      align-items: center;
      border: 1px solid $spaceWhite;
      margin: 0 0 $s 0;

      &:last-child {
        margin: 0;
      }

      @media (max-width: 1520px) {
        max-width: 100%;
      }

      & > span {
        width: 100%;
        max-width: 90px;
        margin: 0 $xs 0 0;

        &:last-child {
          width: 100%;
        }
      }

      & > img {
        margin: 0 $xs 0 0;
      }
    }

    &.second-column {
      & .form-input {
        & > span {
          width: 100%;
          max-width: 48px;
          margin: 0 $xs 0 0;
        }

        &:last-child {
          & > span {
            max-width: 20px;
          }
        }
      }
    }
  }

  &__controllers {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $whiteLight;
    padding: $sm;
    margin: 0 0 $one 0;

    @media (max-width: 1520px) {
      flex-direction: column;
    }
  }

  &__item-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: $s;

    @media (max-width: 730px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 460px) {
      flex-direction: column-reverse;
    }

    &.closed {
      display: none;
    }

    &.opened {
      margin: 1px 0 0 0;
      border-radius: 0 0 $s $s;
      justify-content: flex-start;
      align-items: flex-start;

      @media (max-width: 1060px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.form-data {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 $l 0;
  height: 100%;
  max-height: 68px;
  min-height: 68px;

  @media (max-width: 1010px) {
    flex-direction: column;
    max-height: unset;
    min-height: unset;
  }

  &__layout {
    width: 100%;
    height: 100%;
    max-height: 68px;
    min-height: 68px;
    background-color: $whiteLight;
    padding: $s $m;
    border-radius: $s;
    margin: 0 $l 0 0;

    @media (max-width: 1010px) {
      margin: 0 0 $m 0;
      max-height: unset;
      min-height: unset;
    }

    @media (max-width: 390px) {
      padding: $s;
    }

    & > form {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
      }

      & .form-input {
        margin: 0 $sm 0 0;

        @media (max-width: 750px) {
          margin: 0 0 $sm 0;
        }

        &:first-child {
          & > span {
            max-width: 81px;
          }
        }
      }
    }
  }
}

.certificate-item {
  padding: $sm 6px;
  border-radius: $s;
  width: 100%;
  max-width: 48px;
  min-width: 48px;
  height: 100%;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > span {
    color: $whiteLight;
    @include applyFontSchema($bodyMedium, $mulishFamily, $bold);
  }

  &.a-three-plus {
    background-color: $aaaa;
  }

  &.a-two-plus {
    background-color: $aaa;
  }

  &.a-plus {
    background-color: $aa;
  }

  &.a {
    background-color: $a;
  }

  &.b {
    background-color: $b;
  }

  &.c {
    background-color: $cColor;
  }

  &.d {
    background-color: $d;
  }

  &.e {
    background-color: $e;
  }

  &.f {
    background-color: $f;
  }

  &.g {
    background-color: $g;
  }

  &.selected {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
  }
}

.properties {
  padding: $xl;

  @media (max-width: 390px) {
    padding: $m;
  }

  &__layout {
    width: 100%;
    height: 100%;
    // max-height: 323px;
    // min-height: 323px;
    background-color: $whiteLight;
    border-radius: $m;
    padding: $l $m;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    @media (max-width: 1275px) {
      flex-direction: column;
    }
  }

  & .property-item {
    padding: 0;
    border: none;
    background-color: unset;
  }

  &__settings {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 224px;
    justify-content: space-between;
    @media (max-width: 1275px) {
      gap: 8px;
      max-width: 100%;
    }
  }

  &__edit-panel {
    display: flex;
    flex-direction: row;
    justify-content: end;
    height: 100%;
    max-height: 33px;
    margin: 0 0 $l 0;
    @media (max-width: 1275px) {
      justify-content: end;
    }

    & > img {
      margin: 0 $xl 0 0;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__presentation-icon {
    margin: 0 $xl 0 0;
    width: 100%;
    max-width: 32px;
    cursor: pointer;
  }

  &__presentation-layout {
    display: none;

    &.opened {
      width: 100%;
      min-width: 315px;
      display: flex;
      flex-direction: column;
      background-color: $whiteLight;
      padding: $sm;
      border-radius: $m;
      position: relative;
      box-shadow: 0 0 20px #403b372e;
      right: 130px;
      top: 10px;
      z-index: 3;
    }
  }

  &__presentation-button {
    padding: $sm $xxxl;
    text-align: center;
    border-radius: $s;
    border: 1px solid $brown;
    margin: 0 0 $sm 0;

    & > span {
      color: $brown;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }

    &:last-child {
      margin: 0;
      border: none;
      background-color: $success;

      & > span {
        color: $whiteLight;
        @include applyFontSchema($titleSmall, $mulishFamily, $bold);
      }
    }
  }
}

.eye {
  width: 30px;
  height: 30px;
  margin: 0 $s 0 0;
  &.white path {
    stroke: $whiteLight;
  }
}

.user-info {
  display: flex;
  flex-direction: row;
  padding: $s;
  border-radius: $xxxl;
  border: 1px solid $yellow;
  width: 100%;
  height: 100%;
  max-height: 36px;
  max-width: 185px;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $sm 0;

  & > img {
    &:nth-child(1) {
      width: 22px;
      height: 22px;
      margin: 0 $s 0 0;
    }
  }

  & > span {
    width: 100%;
    min-width: 101px;
    margin: 0 $s 0 0;
    color: $brown;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }

  &__user-layout {
    display: none;

    &.opened {
      width: 300%;
      height: 100%;
      max-width: 371px;
      max-height: 328px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border-radius: $m;
      background-color: $whiteLight;
      position: relative;
      right: 287px;
      top: 175px;
      padding: 4px;
      box-shadow: 0px 0px 8px $black;
      z-index: 10;
      animation: fadeInToLeft 0.4s ease-in-out;

      @media (max-width: 950px) {
        right: 250px;
      }

      @media (max-width: 580px) {
        top: 165px;
      }

      @media (max-width: 460px) {
        right: 203px;
      }

      @media (max-width: 360px) {
        top: 160px;
      }

      & .person-data {
        justify-content: center;

        @media (max-width: 360px) {
          margin: 0 0 $s 0;
        }
      }

      & .user-info__user-layout-profile {
        @media (max-width: 360px) {
          padding: $s $l;
        }
      }
    }
  }

  &__user-layout-profile {
    width: 100%;
    padding: $m $xxxl;
    border-bottom: 1px solid $spaceWhite;
  }

  & .data-item__person-social-data {
    justify-content: center;
  }
}

.triangle {
  position: absolute;
  z-index: 1;
  right: 43px;
  top: -9px;
  rotate: 225deg;
  width: 0;
  height: 0;
  border-top-width: 18px;
  border-top-style: solid;
  border-top-color: transparent;
  border-right-width: 18px;
  border-right-style: solid;
  border-right-color: $whiteLight;
  box-shadow: 0px 0px 7px $black;

  @media (max-width: 950px) {
    right: 79px;
  }

  @media (max-width: 360px) {
    right: 32px;
  }
}

.switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $s;
  // margin: 0 $sm 0 0;
  gap: 12px;

  @media (max-width: 1650px) {
    width: 100%;
    // margin: 0 0 $sm 0;
  }

  & .form-input {
    display: none;
    width: 100%;
    min-width: 170px;
    height: 100%;
    min-height: 48px;
    max-height: 48px;

    // @media (max-width: 1650px) {
    //   margin: 0 $sm 0 0 !important;
    // }

    & > span {
      width: 100%;
      max-width: 92px;
      min-width: 92px;
      color: $brown;
    }

    & > input {
      width: 100%;
    }

    &.switch {
      display: flex;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: $s;
    padding: $sm $l;
    background-color: $maline;
    // margin: 0 $sm 0 0;
    cursor: pointer;

    @media (max-width: 450px) {
      padding: $sm $m;
    }

    & > img {
      margin: 0 $s 0 0;

      @media (max-width: 450px) {
        margin: 0;
      }
    }

    & > span {
      color: $whiteLight;
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);

      @media (max-width: 450px) {
        display: none;
      }
    }

    &.switch {
      background-color: $edit;
      margin: 0;
    }
  }
}

.data-item-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 255px;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 1650px) {
    min-width: unset;
  }

  &.switch {
    display: none;
  }

  &__fg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $spaceWhite;
    height: 100%;
    min-height: 48px;
    max-height: 48px;

    border-radius: $s;

    &.opened {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }

    & > img {
      &.opened {
        rotate: 180deg;
      }
    }
  }

  &__no-agency {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: $sm;
  }

  &__icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    & > img {
      margin: 0 $xs 0 0;

      &:last-child {
        &.opened {
          rotate: 180deg;
        }
      }
    }
  }

  &__layout {
    display: none;

    &.opened {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 255px;
      position: relative;
      background-color: $whiteLight;
      border-bottom-left-radius: $s;
      border-bottom-right-radius: $s;
      border: 1px solid $spaceWhite;
      border-top: unset;

      @media (max-width: 1650px) {
        min-width: unset;
      }
    }
  }

  &__search {
    display: flex;
    flex-direction: row;
    padding: $sm;
    border-bottom: 1px solid $spaceWhite;

    & > input {
      background-color: $whiteLight;
      width: 50%;
    }

    &::placeholder {
      color: $spaceWhite;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }

    & > img {
      margin: 0 $sm 0 0;
    }
  }

  &__item {
    padding: $sm;

    & > img {
      margin: 0 $s 0 0;
    }
  }
}

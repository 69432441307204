.customer-info-container {
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.customer-role {
  padding: 6px 12px;
  border-radius: 8px;
  color: #f3f3f3;
  &__buyer {
    background-color: #e18cd4;
  }
  &__vendor {
    background-color: #88cf66;
  }
}

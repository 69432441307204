.submit-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  max-width: 48px;
  max-height: 48px;
  background-color: $success;
  padding: $s;
  border-radius: $s;
  cursor: pointer;
  transition:
    opacity 0.3s,
    background-color 0.3s;

  &.disabled {
    cursor: not-allowed; // Указатель меняется на запрещающий
    opacity: 0.6; // Изменение прозрачности
    background-color: lighten($success, 20%); // Или измените цвет
  }

  @media (max-width: 800px) {
    max-width: 100%;
    // max-height: 100%;
  }
}

.checkbox {
  display: flex;
  justify-content: center;
  background-color: $whiteLight;
  border: 1.5px solid $brown;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  cursor: pointer;

  & > img {
    display: none;
    width: 70%;
  }

  &.checked {
    & > img {
      display: block;
    }
  }
}

.form-input {
  width: 100%;
  // max-width: 741px;
  height: 100%;
  max-height: 48px;
  min-height: 48px;
  background-color: $whiteLight;
  padding: $sm;
  border-radius: $s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid $spaceWhite;
  @media (max-width: 390px) {
    gap: 4px;
    margin: 0;
    @include applyFontSchema($bodyMedium, $mulishFamily, $bold);
  }

  & > span {
    // width: 100%;
    // max-width: 50px;
    white-space: nowrap;
    margin: 0 $s 0 0;
    color: $brown;
    @include applyFontSchema($bodyLarge, $mulishFamily, $bold);

    @media (max-width: 390px) {
      margin: 0;
      @include applyFontSchema($bodyMedium, $mulishFamily, $bold);
    }
  }

  & > input {
    width: 100%;
    color: $brown;
    background-color: $whiteLight;
    color: $brown;
    @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

    @media (max-width: 390px) {
      @include applyFontSchema($bodyMedium, $mulishFamily, $bold);
    }
  }

  // &:last-child {
  //   max-width: 260px;
  //   height: 100%;
  //   max-height: 68px;
  //   min-height: 68px;
  //   border: none;

  //   @media (max-width: 1010px) {
  //     max-width: 100%;
  //   }

  //   & > input {
  //     padding: $s $m;
  //   }
  // }
}

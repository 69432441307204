@use 'sass:map';

@mixin applyFontSchema($fontSchema, $fontFamily, $fontWeight) {
  $fontsSizes: (
    $displayLarge: 57px,
    $displayMedium: 45px,
    $displaySmall: 36px,
    $headlineLarge: 32px,
    $headlineMedium: 28px,
    $headlineSmall: 24px,
    $titleLarge: 22px,
    $titleMedium: 16px,
    $titleSmall: 14px,
    $labelLarge: 14px,
    $labelMedium: 12px,
    $labelSmall: 11px,
    $bodyLarge: 16px,
    $bodyMedium: 14px,
    $bodySmall: 12px,
    $bodyXSmall: 10px,
  );

  $lineHeights: (
    $displayLarge: 64px,
    $displayMedium: 52px,
    $displaySmall: 44px,
    $headlineLarge: 40px,
    $headlineMedium: 36px,
    $headlineSmall: 32px,
    $titleLarge: 28px,
    $titleMedium: 24px,
    $titleSmall: 20px,
    $labelLarge: 20px,
    $labelMedium: 16px,
    $labelSmall: 16px,
    $bodyLarge: 24px,
    $bodyMedium: 20px,
    $bodySmall: 16px,
    $bodyXSmall: 16px,
  );

  $letterSpacing: (
    $displayLarge: -0.25px,
    $displayMedium: 0,
    $displaySmall: 0,
    $headlineLarge: 0,
    $headlineMedium: 0,
    $headlineSmall: 0,
    $titleLarge: 0,
    $titleMedium: 0.15px,
    $titleSmall: 0.1px,
    $labelLarge: 0.1px,
    $labelMedium: 0.5px,
    $labelSmall: 0.5px,
    $bodyLarge: 0.5px,
    $bodyMedium: 0.25px,
    $bodySmall: 0.4px,
    $bodyXSmall: 0.4px,
  );

  $fontsWeights: (
    $regular: 400,
    $medium: 500,
    $bold: 700,
  );

  font-family: '#{$fontFamily} #{$fontWeight}';
  font-size: map.get($fontsSizes, $fontSchema);
  line-height: map.get($lineHeights, $fontSchema);
  letter-spacing: map.get($letterSpacing, $fontSchema);
  font-weight: map.get($fontsWeights, $fontWeight);
}

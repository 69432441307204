.select-container {
  position: relative;
  // width: 200px;
  width: 100%;

  .select-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #c6c6c6;
    cursor: pointer;
    border-radius: 8px;

    .selected-option {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    // Стрелочка
    img {
      width: 20px; /* размер стрелочки */
      height: 18px;
      transition: transform 0.3s ease; /* плавный переход для поворота */
    }

    // Поворот при открытии
    img.opened {
      transform: rotate(180deg); /* поворот на 180 градусов */
    }
  }

  .options-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 150px;
    background-color: #f3f3f3;
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    margin-top: 5px;
    z-index: 10;
    max-height: 180px;
    overflow-y: scroll;

    .option {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f5f5f5;
      }

      &.selected {
        background-color: #e0e0e0;
      }

      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}

.test-select-class {
  & > div {
    padding: 12px 16px !important;
  }
}

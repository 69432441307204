// .agents {
//   padding: $xl;

//   @media (max-width: 800px) {
//     padding: $l;
//   }

//   @media (max-width: 550px) {
//     padding: $m;
//   }

//   .form-data {
//     @media (max-width: 1650px) {
//       flex-direction: column;
//       max-width: unset;
//       max-height: unset;
//     }

//     &__layout {
//       @media (max-width: 1650px) {
//         max-width: unset;
//         max-height: unset;
//         margin: 0 0 16px 0;
//       }

//       & > form {
//         @media (max-width: 1650px) {
//           flex-direction: column;
//           max-width: unset;
//           max-height: unset;
//         }

//         & .form-input {
//           @media (max-width: 1650px) {
//             width: 100%;
//             max-width: unset;
//             margin: 0 0 $sm 0;
//           }

//           &:last-child {
//             @media (max-width: 1650px) {
//               width: 100%;
//             }
//           }
//         }

//         &.form-input:last-child {
//           @media (max-width: 1650px) {
//             width: 100%;
//           }
//         }

//         & .submit-button {
//           @media (max-width: 1650px) {
//             max-width: 100%;
//             max-height: 100%;
//           }
//         }
//       }
//     }
//   }

//   & .form-input {
//     @media (max-width: 1650px) {
//       width: 100%;
//       max-width: unset;
//     }

//     &:last-child {
//       @media (max-width: 1650px) {
//         width: 100%;
//       }
//     }
//   }
// }

.agents-2 {
  padding: 32px 32px 0;

  @media (max-width: 800px) {
    padding: 16px 12px 0;
  }

  .agents-cards {
    padding: 24px 0;
  }
}

.agents-card__section {
  padding-bottom: 16px;

  @media (max-width: 800px) {
    padding-bottom: 6px;
  }
}

.analytics-item {
  padding: $l;
  background-color: $whiteLight;
  border-radius: $m;
  width: 100%;
  height: 100%;
  max-height: 298px;
  max-width: 250px;
  margin: 0 $l 0 0;

  @media screen and (max-width: 930px) {
    margin: 0 0 $m 0;
  }

  @media screen and (max-width: 930px) {
    max-height: 298px;
    max-width: 377px;
    text-align: center;
    padding: $m;
  }

  & > span {
    color: $brown;
    @include applyFontSchema($headlineMedium, $mulishFamily, $bold);

    @media screen and (max-width: 400px) {
      @include applyFontSchema($titleLarge, $mulishFamily, $bold);
    }
  }

  &.subscribe {
    max-height: 298px;
    max-width: 420px;

    @media screen and (max-width: 450px) {
      max-height: 100%;
    }

    @media screen and (max-width: 930px) {
      margin: 0;
    }
  }

  &__description {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  &__data {
    height: 100%;
    width: 40%;
    position: relative;
    bottom: 30px;
    right: 20px;

    @media screen and (max-width: 450px) {
      bottom: 40px;
      width: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      right: 0;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__data-percent {
    display: flex;
    flex-direction: row;

    & > span {
      margin: 0 0 $m 0;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);

      &:first-child {
        color: $info;
      }

      &:nth-child(2) {
        color: $error;
      }
    }
  }

  &__data-ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 $m 0;

    & > span {
      color: $info;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }

    &:last-child {
      & .analytics-item__data-ul-circle {
        background-color: $error;
      }

      & > span {
        color: $error;
        @include applyFontSchema($titleMedium, $mulishFamily, $bold);
      }
    }

    @media screen and (max-width: 450px) {
      display: flex;
      flex-direction: row;
    }
  }

  &__data-ul-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);
    }

    @media screen and (max-width: 450px) {
      margin: 0 $s 0 0;
    }
  }

  &__data-ul-circle {
    width: 10px;
    height: 10px;
    border-radius: 10000px;
    background-color: $info;
    margin: 0 $s 0 0;
  }
}

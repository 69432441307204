.progress-bar {
  width: 100%;
  height: 100vh;
  max-height: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 20px;

  &.subscribe {
    bottom: 30px;
    right: 27px;

    @media screen and (max-width: 450px) {
      right: 0;
    }
  }

  & > span {
    position: relative;
    z-index: 1;
    top: 115px;
    text-align: center;
    color: $brown;
    @include applyFontSchema($headlineMedium, $mulishFamily, $bold);

    &.title {
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    max-width: 198px;
    max-height: 198px;
    border-radius: 50%;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(closest-side, #f3f3f3 79%, transparent 80% 100%),
      conic-gradient($success 100%, rgb(187, 252, 206) 0);

    @media screen and (max-width: 450px) {
      max-width: 188px;
      max-height: 188px;
    }

    & .progress {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }

  & .subscribe {
    width: 100%;
    height: 100%;
    max-width: 198px;
    max-height: 198px;
    border-radius: 50%;
    position: relative;
    z-index: 0;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(closest-side, #f3f3f3 79%, transparent 80% 100%),
      conic-gradient($info 90%, $error 10%, $error 0);
    rotate: 130deg;

    // @media screen and (max-width: 450px) {
    //   max-width: 188px;
    //   max-height: 188px;
    // }

    & .progress {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
}

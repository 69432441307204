.property-data {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 $l 0;
  height: 100%;
  max-height: 68px;
  min-height: 68px;

  @media (max-width: 700px) {
    flex-direction: column;
    max-height: 100%;
    min-height: 100%;
  }

  & .form-input {
    &.properties {
      width: 100%;
      max-width: 435px;
      height: 100%;
      max-height: 100%;
      background-color: #f3f3f3;
      padding: $l $m;
      border-radius: $s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #c6c6c6;
      margin: 0 $l 0 0;
      @media (max-width: 700px) {
        margin: 0;
      }
    }
  }

  & .agents__drop-down {
    position: relative;
    white-space: nowrap;
    margin: 0;
  }

  & .submit-button {
    max-width: 68px;
    min-width: 68px;
    min-height: 68px;
  }
}

.docs-data-button {
  width: 100%;
  // max-width: 205px;
  height: 100%;
  max-height: 48px;
  border: 1px solid $spaceWhite;
  padding: $m $xl;
  border-radius: $s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 $sm 0;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  & > span {
    color: $brownLight;
    margin: 0 $s 0 0;
    @include applyFontSchema($labelMedium, $mulishFamily, $bold);
  }

  @media (max-width: 1520px) {
    justify-content: center;
    max-width: 100%;

    &:last-child {
      margin: 0 0 $sm 0;
    }
  }
}

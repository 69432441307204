.property-item {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $whiteLight;
  border-bottom-left-radius: $m;
  border-bottom-right-radius: $m;
  padding: $l;
  border-top: 1px solid $brownLight;

  @media (max-width: 350px) {
    padding: $sm;
  }

  &__layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: 323px;

    @media (max-width: 1420px) {
      max-height: unset;
    }

    @media (max-width: 1150px) {
      flex-direction: column;
    }

    & div > img {
      border-radius: $m;
      // margin: 0 $l 0 0;
      max-width: 520px;
      max-height: 275px;
      align-self: center;

      @media (max-width: 1100px) {
        margin: 0;
      }

      @media (max-width: 650px) {
        // margin: 0 0 $s 0;
        max-width: 300px;
        max-height: 275px;
      }
    }
  }

  &__img-container {
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: $l;
    max-width: 420px;
    max-height: 275px;
    @media (max-width: 1150px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__mainPhoto {
    width: 100%;
    height: 100%;
    object-fit: fill;
    max-width: 100% !important;
    max-height: 100% !important;
    @media (max-width: 1150px) {
      max-width: 520px !important;
      max-height: 275px !important;
    }
  }

  &__sold-label {
    position: absolute;
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    text-align: center;

    color: white;
    background-color: #00000099;
    width: 100%;
    max-width: 520px;

    & > div {
      font-size: 40px;
    }
  }

  &__details {
    width: 100%;
    max-width: 793px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1420px) {
      max-width: unset;
    }

    & > p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: $brown;
      max-height: 72px;
      min-height: 72px;
      overflow: hidden;
      margin: 0 0 $l 0;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }
  }

  &__pre-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 $m 0;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  .agency-item.request {
    margin: 0 $sm 0 0;

    @media (max-width: 500px) {
      width: 100%;
      margin: 0 0 $s 0;
    }

    & > span {
      @media (max-width: 500px) {
        @include applyFontSchema($titleSmall, $mulishFamily, $bold);
      }
    }
  }

  .active-users-icon {
    margin: 0 $sm 0 0;

    @media (max-width: 1200px) {
      max-width: unset;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin: 0 0 $s 0;
      justify-content: flex-start;
    }
  }

  &__type {
    background-color: $maline;
    padding: $s $sm;
    border-radius: $s;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      width: 100%;
    }

    & > span {
      color: $whiteLight;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);

      @media (max-width: 500px) {
        @include applyFontSchema($titleSmall, $mulishFamily, $bold);
      }
    }
  }

  &__like {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > img {
      margin: 0 $s 0 0;
    }

    & > span {
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    margin: 0 0 $l 0;
    @media (max-width: 1150px) {
      justify-content: space-between;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      text-align: center;
    }

    & > h2 {
      color: $brown;
      margin: 0 $l 0 0;
      max-height: 72px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
      @include applyFontSchema($headlineMedium, $mulishFamily, $bold);
      font-size: 22px;

      @media (max-width: 500px) {
        @include applyFontSchema($headlineSmall, $mulishFamily, $bold);
      }
    }

    & > span {
      color: $yellow;
      @include applyFontSchema($headlineSmall, $mulishFamily, $bold);
      white-space: nowrap;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $s;
    row-gap: $m;

    @media (max-width: 500px) {
      grid-template-columns: repeat(1, auto);
      width: 100%;
      justify-content: center;
    }
  }
}

.label-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 560px) {
    justify-content: flex-start;
  }

  & > span {
    text-align: left;
    text-transform: uppercase;
    @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

    @media (max-width: 1420px) {
      @include applyFontSchema($bodySmall, $mulishFamily, $regular);
    }

    & > b {
      text-align: left;
      text-transform: uppercase;
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);

      @media (max-width: 1420px) {
        @include applyFontSchema($bodySmall, $mulishFamily, $bold);
      }
    }
  }

  & > img {
    margin: 0 $s 0 0;
  }
}

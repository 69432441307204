.private-image-container {
  padding: 0 $xl 12px;
  display: none;
  &.opened {
    display: flex;
    // margin-bottom: 8px;
    justify-content: center;
    // grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    // flex-direction: column;
    gap: 32px;
    @media (max-width: 900px) {
      gap: 0;
    }
  }
}

.private-image-box {
  // width: 100%;
  // max-width: 100%;
  & > img {
    max-width: 300px;
  }
}

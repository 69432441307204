.toast {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  max-width: 36%;

  pointer-events: none;
  word-break: break-all;

  @media screen and (max-width: 1366px) {
    max-width: 100%;
  }

  & > span {
    color: $whiteLight;

    @include applyFontSchema($bodyLarge, $mulishFamily, $bold);
  }

  & > img {
    margin: 0 $sm 0 0;
  }

  &.success {
    background-color: $success;
  }

  &.warning {
    background-color: $warning;
  }

  &.error {
    background-color: $error;
  }
}
